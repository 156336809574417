import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import iss from './iss.png';
import L from "leaflet"


function Map({ position, zoom }) {
    // const position = [30.042513, -81.575960]
    const issIcon = L.icon({
        iconUrl: iss,
        iconRetnaUrl: iss,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [25, 55],
    });

    return (
        <MapContainer center={position} zoom={zoom}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker className="map-marker" position={position} icon={issIcon}>

                <Popup>
                    The International Space Station is here!
                </Popup>
            </Marker >
        </MapContainer >

    );
}

export default Map;
