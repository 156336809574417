import './App.css';
import React, { useState, useEffect } from 'react';
import Map from './components/Map';

function App() {
  const [loading, setLoading] = useState(true);
  const [longitude, setLongitude] = useState(0.00000);
  const [latitude, setLatitude] = useState(0.00000);

  useEffect(() => {
    whereIsISS()
  }, []
  )

  const whereIsISS = async () => {
    setLoading(true)
    const resp = await fetch('http://api.open-notify.org/iss-now.json')
    const data = await resp.json()
    const { longitude, latitude } = await data.iss_position
    setLongitude(parseFloat(longitude))
    setLatitude(parseFloat(latitude))
    console.log("The International Space Station is currently located at: \n Latitude: " + latitude, "Longitiude: " + longitude)
    setLoading(false)
  }


  return (
    <div className="App tc">
      <h1 className='site-title'>Where is the International Space Station</h1>
      {!loading ? <Map position={{ lat: latitude, lng: longitude }} zoom={6} /> : <h1 className="site-load">Locating The International Space Station!</h1>}
    </div>
  );
}

export default App;
